import './_grid-icon-text.scss';

const gridIconText = function() {
    const sectionWrappers = document.querySelectorAll('.js-grid-icon-text-wrapper');
    if(sectionWrappers.length>0){
        sectionWrappers.forEach((el)=>{
            let heightStep = 34;
            if(window.innerWidth<=768){
                heightStep = 28;
            }
            let maxHeight = 0;
            const itemBoxes = el.querySelectorAll('.item.item-style-box');
            if(itemBoxes.length>0){
                itemBoxes.forEach((item)=>{
                    const title = item.querySelector('.title');
                    if(title.clientHeight>maxHeight){
                        maxHeight = title.clientHeight;
                    }
                });
                for(let i=3; i>1; i--){
                    if(maxHeight>=heightStep*i){
                        itemBoxes.forEach((item)=>{
                            const title = item.querySelector('.title');
                            title.style.height = (heightStep*3) + 'px';
    
                            title.setAttribute('data-line-limit', 3);
        
                            if(window.innerWidth<=540){
                                title.setAttribute('data-line-limit', 0);
                                title.style.height = 'auto';
                            }
                        });
                        break;
                    }
                }
            }
        });
    }
};

document.addEventListener('DOMContentLoaded', function(){
    gridIconText()
}, false);

window.addEventListener('acf/loaded/grid-icon-text', function (e) {
    gridIconText()
});